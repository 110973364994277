<template>
  <div class="LoginScanCode">

  </div>
</template>

<script>
import service from "../utils/axios";
import { ElNotification } from 'element-plus'

export default {
  name: "LoginScanCode",
  data() {
    return {}
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      const data = await service.post('/dwart/user/user/scan/code/login/dp', {
        code: this.$route.query.code
      })

      if (data.result) {
        localStorage.setItem('token', data.token);
        localStorage.setItem('login-data', JSON.stringify(data));
        history.push({pathname: '/map',})

      } else {
        ElNotification.error(data.message)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.LoginScanCode {

}
</style>
